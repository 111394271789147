import React, { useState, useEffect, useLayoutEffect } from "react";
import styles from "./FormHome.style";
import {
  Grid,
  Typography,
  Button,
  Box,
  CardActionArea,
  CardMedia,
  CardContent,
} from "@mui/material";
import { Card } from "react-bootstrap";
import Swal from "sweetalert2";
const FormHome = () => {
  const classes = styles;
  const [isMobile, setIsMobile] = useState(false);
  const [isTab, setIsTab] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useLayoutEffect(() => {
    // Function to update screen width
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Change 768 to the desired breakpoint
      setIsTab(window.innerWidth < 1080);
    };

    // Initial check
    handleResize();

    // Listen for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (number: string) => {
    const phoneRegex = /^[0-9]{10,11}$/;
    return phoneRegex.test(number);
  };

  const sendEnquiry = async () => {
    if (!validateEmail(email)) {
      Swal.fire({
        title: "Invalid Email",
        text: "Please enter a valid email address.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    if (!validatePhoneNumber(contact)) {
      Swal.fire({
        title: "Invalid Phone Number",
        text: "Please enter a 10-digit phone number.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }
    try {
      setIsLoading(true);
      const response = await fetch(
        "https://backendapi.axisinfoline.com/enquiry/gaanvwala/send",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            contact,
            message,
          }),
        }
      );

      if (response.ok) {
        // Display success message
        Swal.fire({
          title: "Success!",
          text: "Your enquiry has been sent successfully.",
          icon: "success",
          confirmButtonText: "OK",
        });
        setEmail("");
        setName("");
        setMessage("");
        setContact("");
      } else {
        // Display error message if response is not ok
        Swal.fire({
          title: "Error",
          text: "Something went wrong. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      // Handle any other errors
      Swal.fire({
        title: "Error",
        text: "An error occurred. Please try again later.",
        icon: "error",
        confirmButtonText: "OK",
      });
      console.error("Error:", error);
    }
    setIsLoading(false);
  };
  return (
    <div
      style={{
        padding: isTab ? "0px" : "0px 40px",
        width: "100%",
        marginTop: isTab ? "20px" : "0px",
        borderRadius: "10px",
      }}
    >
      <Grid
        container
        spacing={2}
        style={{ padding: isTab ? "20px" : "0px 0px 0px 40px" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          sm={5}
          display={isMobile ? "none" : "block"}
          margin="auto"
        >
          <img
            src="./images/indiaMap.png"
            style={{
              width: "100%",
              height: isTab ? "300px" : "593px",
              objectFit: "contain",
            }}
          />
        </Grid>
        <Grid
          container
          xs={12}
          sm={isTab ? 12 : 5}
          display="flex"
          borderRadius="8px"
          padding={isTab ? "20px" : "30px 20px"}
          ml={isTab ? "12px" : "0px"}
          width={isTab ? "100%" : "auto"}
          boxShadow="0px 6px 40px 0px #0000000F"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: isTab ? "column" : "row",
              gap: isTab ? "15px" : "10px",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                padding: "5px 20px 5px 10px",
                marginBottom: isTab ? "0px" : "15px",
                background: "#F3F7FB",
              }}
            >
              <input
                value={name}
                placeholder="Enter Your Name "
                style={{ ...classes.input, fontFamily: "Outfit", width: "90%" }}
                onChange={(e) => setName(e.target.value)}
              />

              <img src="./images/name.svg" style={{ width: "20px" }} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                padding: "5px 20px 5px 10px",
                marginBottom: "15px",
                background: "#F3F7FB",
              }}
            >
              <input
                value={contact}
                placeholder="Enter Your Phone Number "
                style={{
                  ...classes.input,
                  fontFamily: "Outfit",
                  width: isTab ? "100%" : "90%",
                }}
                onChange={(e) => setContact(e.target.value)}
              />

              <img
                src="./images/telephone.png"
                style={{ width: "20px", objectFit: "contain" }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                padding: "5px 20px 5px 10px",
                marginBottom: "15px",
                background: "#F3F7FB",
              }}
            >
              <input
                value={email}
                placeholder="Enter Your Email "
                style={{
                  ...classes.input,
                  fontFamily: "Outfit",
                  width: isTab ? "100%" : "90%",
                }}
                onChange={(e) => setEmail(e.target.value)}
              />

              <img src="./images/email-grey.svg" style={{ width: "20px" }} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              padding: "5px 20px 5px 10px",
              marginBottom: "15px",
              background: "#F3F7FB",
              alignItems: "flex-start",
            }}
          >
            <textarea
              placeholder="Message"
              value={message}
              style={{
                ...classes.input,
                fontFamily: "Outfit",
                width: isTab ? "100%" : "90%",
                height: isTab ? "130px" : "241px",
              }}
              onChange={(e) => setMessage(e.target.value)}
            />

            <img
              src="./images/msg-grey.svg"
              style={{ width: "20px", margin: "10px 0px" }}
            />
          </div>

          <Button
            variant="contained"
            color="primary"
            style={{
              width: isTab ? "fit-content" : "100%",
              height: isTab ? "40px" : "50px",
              background: "#1377C0",
              borderRadius: "8px",
              color: "#fff",
              fontWeight: "500",
              // marginTop: '20px',
              fontSize: isTab ? "12px" : "14px",
              display: "flex",
              alignItems: "center",
              fontFamily: "Outfit",
              margin: isTab ? "auto" : "0px",
            }}
            disabled={isLoading}
            onClick={sendEnquiry}
          >
            <span style={{ textWrap: "nowrap" }}>SEND MESSAGE</span>
            {isLoading ? (
              <>
                <svg
                  version="1.1"
                  id="L9"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="40px"
                  height="40px"
                  viewBox="0 0 100 100"
                  enable-background="new 0 0 0 0"
                >
                  <path
                    fill="#fff"
                    d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                  >
                    <animateTransform
                      attributeName="transform"
                      attributeType="XML"
                      type="rotate"
                      dur="1s"
                      from="0 50 50"
                      to="360 50 50"
                      repeatCount="indefinite"
                    />
                  </path>
                </svg>
              </>
            ) : (
              <>
                <span style={{ fontSize: "30px", padding: "0px 0px 6px 6px" }}>
                  →
                </span>
              </>
            )}
          </Button>
        </Grid>
      </Grid>

      {/* View More Button */}
    </div>
  );
};
export default FormHome;
